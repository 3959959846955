export default function authHeader(upload = false) {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
        if (upload) {
            return { "Content-Type": "multipart/form-data",Authorization: 'Bearer ' + user.access_token };
        } else {
            return { Authorization: 'Bearer ' + user.access_token };
        }

    } else {
        return {};
    }
}